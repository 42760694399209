import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "ballet-west",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ballet-west",
        "aria-label": "ballet west permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Ballet West`}</h1>
    <blockquote>
      <p parentName="blockquote">{`🔇 We appreciate you silencing your phone.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Please tap an image below to view the digital publication.`}</p>
    </blockquote>
    {
      /***********************CURRENT***********************/
    }
    {
      /***********************CURRENT***********************/
    }
    {
      /***********************CURRENT***********************/
    }
    <h2>Tonight’s Show</h2>
    <section className="home-list">
      <Link to="/wedding-2023" alt="Link to The Wedding web publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgb/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhADEAAAAX8oE3TFgl7vr7PPnUB//8QAGxAAAwEAAwEAAAAAAAAAAAAAAQIDABITIjH/2gAIAQEAAQUC+FG5AbvLyi3sVXJQDTcFR6DSTGbZJLx//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEQ/9oACAEDAQE/Acqv/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHRAAAgMAAgMAAAAAAAAAAAAAAAECESEQIjEyQf/aAAgBAQAGPwJtywZpPwt4duqJxWZg7+o2NmR09InaKs//xAAdEAEBAQACAgMAAAAAAAAAAAABEQAhMUFhUXHx/9oACAEBAAE/IefoSnWTmdPeTOVfWokDpdNspwLesqAaiLgJAr2XCMD2vhmulPzhlHKGKeL+5Ai8pv/aAAwDAQACAAMAAAAQwyiC/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBECH/2gAIAQMBAT8QeDmz/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAECAQE/ENREf//EACAQAQACAgEEAwAAAAAAAAAAAAEAESExQVFhcdGBkaH/2gAIAQEAAT8QcdamJXyiiIxlNlXzAkDkRRA/gBiwK/X3HYDmFWrpyd4+kXpCZNU1bXrRGlQq6IYUsN7FizXGors2mrp+yV0IuB1x5RSzWja35n//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/12c56631415f67125059a853fd3cc0ed/8ac56/wedding-cover.webp 240w", "/static/12c56631415f67125059a853fd3cc0ed/d3be9/wedding-cover.webp 480w", "/static/12c56631415f67125059a853fd3cc0ed/87ca7/wedding-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/12c56631415f67125059a853fd3cc0ed/09b79/wedding-cover.jpg 240w", "/static/12c56631415f67125059a853fd3cc0ed/7cc5e/wedding-cover.jpg 480w", "/static/12c56631415f67125059a853fd3cc0ed/1a144/wedding-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/12c56631415f67125059a853fd3cc0ed/1a144/wedding-cover.jpg",
                "alt": "The Wedding 2023 Cover",
                "title": "The Wedding 2023 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">The Wedding</div>
      </Link>
      <Link to="/snow-white-2023" alt="Link to Snow White web publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAF8pVL0iBYnTtUnORor/8QAHBAAAgIDAQEAAAAAAAAAAAAAAQIAAwQREhMh/9oACAEBAAEFAtGaMAl9hUu5EpPdeUpN2Si+eKvND9Q+gifV/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BKf/EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAgEBPwHSoj//xAAgEAACAQQBBQAAAAAAAAAAAAAAASECESIxURJBYXGB/9oACAEBAAY/AnPw4JOmneyIvsTFbstF9ZR5RTyYJP2LGknZ/8QAIBAAAwABAwUBAAAAAAAAAAAAAAERITFBYVFxgZGhsf/aAAgBAQABPyF3ZV0FdtTGTReCqpOwu5fuPVpRNdeWSKt8vAOWaabLoqtRN6IqR2egjS/sf//aAAwDAQACAAMAAAAQGNFC/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxBZBT//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAECAQE/EHDxRR//xAAeEAEBAAICAwEBAAAAAAAAAAABEQAhMUFhcYGRUf/aAAgBAQABPxAiYJwGvuHYECraaj9wJpdnhk2TIVNFT1z1g7YKp7jrnzrFQVSpm2a3i/Id3AVnnf5it0RDCUJ+5VkJBEV/nqYMruyT1ggtw12rx8jhLIrYpzn/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d2fc42b0e0a99847b8a3d8d38d863d6c/8ac56/snow-white-cover.webp 240w", "/static/d2fc42b0e0a99847b8a3d8d38d863d6c/d3be9/snow-white-cover.webp 480w", "/static/d2fc42b0e0a99847b8a3d8d38d863d6c/87ca7/snow-white-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/d2fc42b0e0a99847b8a3d8d38d863d6c/09b79/snow-white-cover.jpg 240w", "/static/d2fc42b0e0a99847b8a3d8d38d863d6c/7cc5e/snow-white-cover.jpg 480w", "/static/d2fc42b0e0a99847b8a3d8d38d863d6c/1a144/snow-white-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/d2fc42b0e0a99847b8a3d8d38d863d6c/1a144/snow-white-cover.jpg",
                "alt": "Snow White 2023 Cover",
                "title": "Snow White 2023 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Snow White</div>
      </Link>
      <Link to="/sleeping-beauty-2023" alt="Link to Sleeping Beauty web publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQCAwUB/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAdqXcia1ysRKt+AoD5L/AP/EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESAwT/2gAIAQEAAQUCp6uxXQk+fbZJdtWpTvA4szMXn5n/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEQEv/aAAgBAwEBPwFVocH/xAAXEQEBAQEAAAAAAAAAAAAAAAAAARES/9oACAECAQE/AbWrHL//xAAeEAABBAIDAQAAAAAAAAAAAAABAAIRIRASMUGRUf/aAAgBAQAGPwKdq+KAVeDfGCWuEI7SD2omVQHqtrfVbBK//8QAHhAAAwACAQUAAAAAAAAAAAAAAAERUWEhEDFBgZH/2gAIAQEAAT8hV/I+PYikajzNeukJZLDAqXCu7DVgJsSK0ug7OWDRfzlthuUb9yH51P/aAAwDAQACAAMAAAAQqAJO/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxBUQjL/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxCCFVP/xAAfEAEAAgICAgMAAAAAAAAAAAABABEhQTFRYYFxkbH/2gAIAQEAAT8QC50KR075l/ZKkUgAA2ooliU8Qn3ErpZOfVzPADFhb1Avt7Y46qphb4hoV3pILgxu1k8+YXM2wJFZRsTCn18xxQbXp+z/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/52c94246509790f04fc340d1aaed2112/8ac56/sleeping-cover-2023.webp 240w", "/static/52c94246509790f04fc340d1aaed2112/d3be9/sleeping-cover-2023.webp 480w", "/static/52c94246509790f04fc340d1aaed2112/87ca7/sleeping-cover-2023.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/52c94246509790f04fc340d1aaed2112/09b79/sleeping-cover-2023.jpg 240w", "/static/52c94246509790f04fc340d1aaed2112/7cc5e/sleeping-cover-2023.jpg 480w", "/static/52c94246509790f04fc340d1aaed2112/1a144/sleeping-cover-2023.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/52c94246509790f04fc340d1aaed2112/1a144/sleeping-cover-2023.jpg",
                "alt": "Sleeping Beauty 2023 Cover",
                "title": "Sleeping Beauty 2023 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">The Sleeping Beauty</div>
      </Link>
      <a href="https://balletwest.org" alt="Link to Ballet West website." target="_blank" rel="noopener noreferrer">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBBAUD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAeMOk1aFBeaCJvGUJ//EAB4QAAICAgIDAAAAAAAAAAAAAAECAAMSEwQRFCEy/9oACAEBAAEFAvGUxeN70LGLGDMH6naBs64uHVjtsIsJq3Y//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AXD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8BXK//xAAdEAACAgMAAwAAAAAAAAAAAAAAARExAiEiEjKR/9oACAEBAAY/AuWXJR7GjqJGvFFL6UjJpEmsoP/EAB0QAAICAwADAAAAAAAAAAAAAAERACFBUWExgcH/2gAIAQEAAT8hF0ptUIQYHRAj4hVhRqJHjIg+9CSK1uyYHG8YRyqVOKpWL5DoAhpzRD8Gf//aAAwDAQACAAMAAAAQpMlC/8QAGREAAgMBAAAAAAAAAAAAAAAAABEBMVFh/9oACAEDAQE/EHwUaWOf/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERQVH/2gAIAQIBAT8Qj6UsIkSj/8QAHhABAQADAAIDAQAAAAAAAAAAAREAITFBUWGBkbH/2gAIAQEAAT8QBhOlSxe/Xc2oQGR9mkX05EodYa7R0NmDjIErr88df3A3kkQ2but83jtzoVCC+NGMiBGjSVcqzoRb/d5UM+nh0GfHzgf0DSTkXEUCEUs0Z//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4961bda345aaab5ab1bba65c0f3e0159/8ac56/placeholder.webp 240w", "/static/4961bda345aaab5ab1bba65c0f3e0159/d3be9/placeholder.webp 480w", "/static/4961bda345aaab5ab1bba65c0f3e0159/87ca7/placeholder.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4961bda345aaab5ab1bba65c0f3e0159/09b79/placeholder.jpg 240w", "/static/4961bda345aaab5ab1bba65c0f3e0159/7cc5e/placeholder.jpg 480w", "/static/4961bda345aaab5ab1bba65c0f3e0159/1a144/placeholder.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4961bda345aaab5ab1bba65c0f3e0159/1a144/placeholder.jpg",
                "alt": "Placeholder",
                "title": "Placeholder",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Ballet West Website</div>
      </a>
      <Link to="/nutcracker-2022" alt="Link to The Nutcracker web publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAegts005UVoaZ6WKif/EABoQAQEBAQADAAAAAAAAAAAAAAECABESISP/2gAIAQEAAQUC8XvRea1kihc4gL1Fb6aT1//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVVX/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8BIf/EAB0QAAIBBAMAAAAAAAAAAAAAAAABERAhIjEygZH/2gAIAQEABj8CmehpO6Lkolbe6yqYpM4L0yVz/8QAHBABAQEAAwADAAAAAAAAAAAAAQARITFhQVGR/9oACAEBAAE/ITTqj+L1kIQctbAGh2fM8D7EknExjBpcxXhPWyp3qeG//9oADAMBAAIAAwAAABDE63P/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEQ/9oACAEDAQE/EB3gZav/xAAXEQEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EIox/8QAHxABAAICAgIDAAAAAAAAAAAAAQARITFBUWFxgdHw/9oACAEBAAE/ECu1pBWHDDV/1DUrgdgVAhc6paeIJ9TZx4N/Eyy5GErsZTkRGhz+7lLNV7h+6oZRO4koJ03+4ISucKlcT//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0f1e40ee58df939b08c589a31ce3b357/8ac56/nutcracker-cover-2022.webp 240w", "/static/0f1e40ee58df939b08c589a31ce3b357/d3be9/nutcracker-cover-2022.webp 480w", "/static/0f1e40ee58df939b08c589a31ce3b357/87ca7/nutcracker-cover-2022.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/0f1e40ee58df939b08c589a31ce3b357/09b79/nutcracker-cover-2022.jpg 240w", "/static/0f1e40ee58df939b08c589a31ce3b357/7cc5e/nutcracker-cover-2022.jpg 480w", "/static/0f1e40ee58df939b08c589a31ce3b357/1a144/nutcracker-cover-2022.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/0f1e40ee58df939b08c589a31ce3b357/1a144/nutcracker-cover-2022.jpg",
                "alt": "Nutcracker 2022 Cover",
                "title": "Nutcracker 2022 Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">The Nutcracker</div>
      </Link>
      <Link to="/rodeo-2022" alt="Link to Rodeo web publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAQP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAGlzakZbVzRQurUUo7mgv/EABwQAAICAwEBAAAAAAAAAAAAAAECAAMREiEEE//aAAgBAQABBQJtki3bgQ9FWU9Mtt+cDB7cwgRg+UXn/8QAGREAAgMBAAAAAAAAAAAAAAAAAAECEBES/9oACAEDAQE/AYpV0af/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEhD/2gAIAQIBAT8ByUv/xAAeEAABAwQDAAAAAAAAAAAAAAABAAIRECEiMhIxYf/aAAgBAQAGPwIu28VhDp6V6cSKayg4VwaIWQuv/8QAHxAAAgEEAgMAAAAAAAAAAAAAAREAECFBUTFxYZGh/9oACAEBAAE/ITRkh1AEQhil32MBczBaGmOYPWDoZDYm3kUAYPuN/BuN3mDP/9oADAMBAAIAAwAAABCD9w//xAAXEQEBAQEAAAAAAAAAAAAAAAABABAR/9oACAEDAQE/EECrgC5v/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAgEBPxDFt1f/xAAfEAEBAAICAQUAAAAAAAAAAAABEQAxIUFREIGRoeH/2gAIAQEAAT8QtHZFofO5jstigwNh7MMpW2ExQ00ILMP2ArjpOE+vQskiVvXcNvHtmitQXhsjjyxMrr4/WC+Bp1z35uNUCnCavHef/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/8ac56/rodeo-cover.webp 240w", "/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/d3be9/rodeo-cover.webp 480w", "/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/87ca7/rodeo-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/09b79/rodeo-cover.jpg 240w", "/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/7cc5e/rodeo-cover.jpg 480w", "/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/1a144/rodeo-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/ca2cb84a2f8626c455fb57b6c5c0b5c2/1a144/rodeo-cover.jpg",
                "alt": "Rodeo Cover",
                "title": "Rodeo Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Rodeo</div>
      </Link>
      <Link to="/onegin-2022" alt="Link to Onegin web publication." mdxType="Link">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "730px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "155.83333333333334%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQCAwUG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAWaIoDqlYzQr0qRSZrDP/8QAGxAAAgMBAQEAAAAAAAAAAAAAAQIAAxEhEyL/2gAIAQEAAQUC7r24ZY7LNDvstPzXx4aKjPAxKVI//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHxAAAQQABwAAAAAAAAAAAAAAAQACERIQICEiMjOB/9oACAEBAAY/AiZ8QA1M4SAITairgcvBdLFvYJX/xAAfEAEAAgIBBQEAAAAAAAAAAAABACERMUEQUWGRseH/2gAIAQEAAT8hpmUfSDazBCCm3MSyB3nmUSsErPRqd4rHmotzaBh8i/2ZSfxZ/9oADAMBAAIAAwAAABCbLgz/xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/EAJwSTTf/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERECH/2gAIAQIBAT8Q7cadKf/EAB8QAQADAAICAwEAAAAAAAAAAAEAESExQVFxYZHR8P/aAAgBAQABPxBtHEkFY6ZVkIhxfcMol5Mi6pdDevjwQdPKpit3XqOoJd/CEOHZUYQ4kNqq4tf2FWzMaLff2mzTRGSuu5//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3348397cf7bca38304790d8375f111b4/8ac56/onegin-cover.webp 240w", "/static/3348397cf7bca38304790d8375f111b4/d3be9/onegin-cover.webp 480w", "/static/3348397cf7bca38304790d8375f111b4/87ca7/onegin-cover.webp 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/3348397cf7bca38304790d8375f111b4/09b79/onegin-cover.jpg 240w", "/static/3348397cf7bca38304790d8375f111b4/7cc5e/onegin-cover.jpg 480w", "/static/3348397cf7bca38304790d8375f111b4/1a144/onegin-cover.jpg 730w"],
                "sizes": "(max-width: 730px) 100vw, 730px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/3348397cf7bca38304790d8375f111b4/1a144/onegin-cover.jpg",
                "alt": "Onegin Cover",
                "title": "Onegin Cover",
                "loading": "lazy",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Onegin</div>
      </Link>
    </section>
    {
      /***********************END CURRENT***********************/
    }
    {
      /***********************END CURRENT***********************/
    }
    {
      /***********************END CURRENT***********************/
    }
    <hr />
    <h2 {...{
      "id": "past-shows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#past-shows",
        "aria-label": "past shows permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Past Shows`}</h2>
    <div id="PastAnchor">
      <h4 {...{
        "id": "please-tap-a-button-below-to-view-the-past-publications",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#please-tap-a-button-below-to-view-the-past-publications",
          "aria-label": "please tap a button below to view the past publications permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Please tap a button below to view the past publications.`}</h4>
      <details>
        <summary>2021–22 Season</summary>
        <section className="home-list past">
          <Link to="/choreographic-2022" alt="Link to Choreographic Fest V web publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAejomWasQicsn2MOJ//EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMREiEj/9oACAEBAAEFAvr27qgEssFcZOzH9FerNhrGMrgImr//xAAYEQACAwAAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPwGNKZ//xAAWEQEBAQAAAAAAAAAAAAAAAAAAIRD/2gAIAQIBAT8ByI//xAAeEAABAwUBAQAAAAAAAAAAAAABABExEBIhIkECUf/aAAgBAQAGPwIxbxbGgeE5+UYdkq0lxTEp28rYZX//xAAgEAACAgEDBQAAAAAAAAAAAAABEQAxIRBBYVFxgZHB/9oACAEBAAE/IRuD9kakfZS24AKw5zUDftcGn0gJAo+ZlDDwJtGSAABHfSwXg5lR7OCYjgZ//9oADAMBAAIAAwAAABDjKE3/xAAXEQADAQAAAAAAAAAAAAAAAAABEBFB/9oACAEDAQE/EANUQ//EABYRAQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPxBMU//EAB4QAQEAAgMAAwEAAAAAAAAAAAERACExQVFhobHB/9oACAEBAAE/EAHXUAkB2uQF0UOWPDvE7UNinA9YJdgQBE5ne/ML2tiSFSvdYUNJrgv7mlkIVj+vxlGcpyNkcj3NeCOVl+ZipdGbPtyCFOGn7n//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/a30e65c94661c435eb9b03311e98079c/8ac56/choreographic-2022-cover.webp 240w", "/static/a30e65c94661c435eb9b03311e98079c/d3be9/choreographic-2022-cover.webp 480w", "/static/a30e65c94661c435eb9b03311e98079c/87ca7/choreographic-2022-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/a30e65c94661c435eb9b03311e98079c/09b79/choreographic-2022-cover.jpg 240w", "/static/a30e65c94661c435eb9b03311e98079c/7cc5e/choreographic-2022-cover.jpg 480w", "/static/a30e65c94661c435eb9b03311e98079c/1a144/choreographic-2022-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/a30e65c94661c435eb9b03311e98079c/1a144/choreographic-2022-cover.jpg",
                    "alt": "Choreographic Fest V Cover",
                    "title": "Choreographic Fest V Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Choreographic Fest V</div>
          </Link>
          <Link to="/carmina-2022" alt="Link to Carmina Burana with glass pieces web publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADAv/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABSnoeNLtaM80GpVxJ/8QAGhAAAwEBAQEAAAAAAAAAAAAAAQISABEDIf/aAAgBAQABBQII9SNAz1I9D3uIsSAe4p862QUv/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BKf/EABYRAQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPwHIj//EACEQAAAFAgcAAAAAAAAAAAAAAAABAiExEBEDEiIyUXGh/9oACAEBAAY/AjjLXS3Yw1WZUnR44CbRRhs9FzYf/8QAHRAAAgMAAgMAAAAAAAAAAAAAAREAIUExURBhkf/aAAgBAQABPyENwPED1Tg0udIYtwRSnIRxACqAIOf6hHqtxngvU+ypgbOaxJdNz//aAAwDAQACAAMAAAAQv+aP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxDIKj//xAAXEQEBAQEAAAAAAAAAAAAAAAAAEQEh/9oACAECAQE/EO6qlv/EAB4QAQACAgIDAQAAAAAAAAAAAAEAESExUWFBkbGB/9oACAEBAAE/EMsMDnXlZSWsLC8pzAWLbjXUSS2CxhDZ+nmLk2icLdPCxO1DgalyZieHvrqAymLs6NmPcpzEx3xlL3UTaOjGPybotKfap//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e4b7c35b8d77ea226a67d84e95ed0fe5/8ac56/carmina-cover.webp 240w", "/static/e4b7c35b8d77ea226a67d84e95ed0fe5/d3be9/carmina-cover.webp 480w", "/static/e4b7c35b8d77ea226a67d84e95ed0fe5/87ca7/carmina-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e4b7c35b8d77ea226a67d84e95ed0fe5/09b79/carmina-cover.jpg 240w", "/static/e4b7c35b8d77ea226a67d84e95ed0fe5/7cc5e/carmina-cover.jpg 480w", "/static/e4b7c35b8d77ea226a67d84e95ed0fe5/1a144/carmina-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/e4b7c35b8d77ea226a67d84e95ed0fe5/1a144/carmina-cover.jpg",
                    "alt": "Camina Burana with Glass Pieces Cover",
                    "title": "Camina Burana with Glass Pieces Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Carmina Burana with Glass Pieces</div>
          </Link>
          <Link to="/romeo-juliet-2022" alt="Link to Ballet West Romeo and Juliet publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIDAQQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB9CkHzuhoUseuDlzQf//EAB4QAAICAAcAAAAAAAAAAAAAAAECABEDEBIhIiMx/9oACAEBAAEFAuzXfKY0Vi2TC1GwhWgPFSx//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPwHKio//xAAWEQEBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8ByI//xAAcEAACAgIDAAAAAAAAAAAAAAAAARAhElERMkH/2gAIAQEABj8CfXHwxhUXDRzqXTNH/8QAHBAAAgMBAAMAAAAAAAAAAAAAAREAIUFREDFh/9oACAEBAAE/IWLrCuoR0acXS4RBMAUWCcM5D4N8AMaINB2uOPS2h6lTJvVLkU4Z/9oADAMBAAIAAwAAABBsAMP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEWH/2gAIAQMBAT8QkVo2f//EABcRAAMBAAAAAAAAAAAAAAAAAAAQETH/2gAIAQIBAT8Q1UUf/8QAIRABAQACAgEEAwAAAAAAAAAAAREAITFhQVFxobGBkeH/2gAIAQEAAT8QlLErrsu/jrI8VdzUH+4MvkapJ1gSaRKFxxj1iD3H64zfn4ziIQzVqZD1IX7yHY0fOLXFGDb1tyBJBSir+clo0QAfpz//2Q==')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/87b048f71ffbc899b3d6c53388ef2157/8ac56/romeo-cover.webp 240w", "/static/87b048f71ffbc899b3d6c53388ef2157/d3be9/romeo-cover.webp 480w", "/static/87b048f71ffbc899b3d6c53388ef2157/87ca7/romeo-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/87b048f71ffbc899b3d6c53388ef2157/09b79/romeo-cover.jpg 240w", "/static/87b048f71ffbc899b3d6c53388ef2157/7cc5e/romeo-cover.jpg 480w", "/static/87b048f71ffbc899b3d6c53388ef2157/1a144/romeo-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/87b048f71ffbc899b3d6c53388ef2157/1a144/romeo-cover.jpg",
                    "alt": "Romeo and Juliet Cover",
                    "title": "Romeo and Juliet Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">Romeo + Juliet</div>
          </Link>
          <Link to="/nutcracker-2021" alt="Link to Ballet West The Nutcracker 2021 publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEAf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdi8ONGqSQtWW5e7C//EAB0QAAICAQUAAAAAAAAAAAAAAAACARESECEiIzH/2gAIAQEAAQUC7M7KG8XK99EWIYlTIXlH/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAER/9oACAEDAQE/AYaf/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AVTP/8QAIBAAAAQGAwAAAAAAAAAAAAAAAAEQMQIREiAhMkFhcf/aAAgBAQAGPwI2p4sYvViXQ89ibD//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAhMWEQQVGR/9oACAEBAAE/IV3MELRxUGzlmaU9w7SBciIEIijEPoZxwgVcCX8SDaB0M//aAAwDAQACAAMAAAAQ4zUx/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIYH/2gAIAQMBAT8QSo6LBT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QtaUx/8QAHhABAQACAgIDAAAAAAAAAAAAAREAIUFRMWEQcbH/2gAIAQEAAT8QhvYgJEOVwUAVV6cQG+Tnj1hFszbdH3gKUercKwwFKvZzloDoRNOOVwkNRLDrz8VW11ZcRpNRe3xvebs9Sg/mf//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/a0dcd0a30eafa649680581be67b1e885/8ac56/nut-cover.webp 240w", "/static/a0dcd0a30eafa649680581be67b1e885/d3be9/nut-cover.webp 480w", "/static/a0dcd0a30eafa649680581be67b1e885/87ca7/nut-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/a0dcd0a30eafa649680581be67b1e885/09b79/nut-cover.jpg 240w", "/static/a0dcd0a30eafa649680581be67b1e885/7cc5e/nut-cover.jpg 480w", "/static/a0dcd0a30eafa649680581be67b1e885/1a144/nut-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/a0dcd0a30eafa649680581be67b1e885/1a144/nut-cover.jpg",
                    "alt": "The Nutcracker 2021 Cover",
                    "title": "The Nutcracker 2021 Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">The Nutcracker</div>
          </Link>
          <Link to="/mermaid-2021" alt="Link to Ballet West The Little Mermaid publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/2gAMAwEAAhADEAAAAb95eE1jCTJfhlxgr//EAB4QAAIBAwUAAAAAAAAAAAAAAAECAwAREhATIiMx/9oACAEBAAEFAuzPSSYIwctJTRruYG48N6YuKj5L/8QAGREAAgMBAAAAAAAAAAAAAAAAAQIAEBEx/9oACAEDAQE/ASM7MjKGr//EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8BtQxf/8QAHhAAAQMFAQEAAAAAAAAAAAAAAQAQEQISITFBMpH/2gAIAQEABj8COrePCpBENQbetnawF5H1SQv/xAAeEAEAAgICAwEAAAAAAAAAAAABABEhQTFRYXGBkf/aAAgBAQABPyE7mFhywK5bgGi1zOxqyfkPMqDLNxqo6u7sja/aEGN9spt8JwJvuf/aAAwDAQACAAMAAAAQAxb/AP/EABgRAAMBAQAAAAAAAAAAAAAAAAABERAh/9oACAEDAQE/EIE8JFOH/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQETFB/9oACAECAQE/EFgix+I//8QAHxABAQACAgIDAQAAAAAAAAAAAREAITFhQXFRkfDx/9oACAEBAAE/EI3tQ0iHlceAHtxa8ilJOsN22gYP8w2RBBuvPprAzSPWQmWlEmyr4NzGMsVTcN03rG6ASTxesMW/iEwudxPz7wCO4hWrn//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/28fc6e0a02a0dab67b3f88dd066c9e0b/8ac56/little_mermaid_cover.webp 240w", "/static/28fc6e0a02a0dab67b3f88dd066c9e0b/d3be9/little_mermaid_cover.webp 480w", "/static/28fc6e0a02a0dab67b3f88dd066c9e0b/87ca7/little_mermaid_cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/28fc6e0a02a0dab67b3f88dd066c9e0b/09b79/little_mermaid_cover.jpg 240w", "/static/28fc6e0a02a0dab67b3f88dd066c9e0b/7cc5e/little_mermaid_cover.jpg 480w", "/static/28fc6e0a02a0dab67b3f88dd066c9e0b/1a144/little_mermaid_cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/28fc6e0a02a0dab67b3f88dd066c9e0b/1a144/little_mermaid_cover.jpg",
                    "alt": "The Little Mermaid Cover",
                    "title": "The Little Mermaid Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
  <div className="homeButtons">The Little Mermaid</div>
          </Link>
          <Link to="/dracula-2021" alt="Link to Ballet West Dracula publication." mdxType="Link">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "730px"
                }
              }}>{`
      `}<span parentName="span" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "155.83333333333334%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgME/9oADAMBAAIQAxAAAAFbaInsYLhNdpyrJkMAn//EABwQAQACAwADAAAAAAAAAAAAAAEAAgMREhAhIv/aAAgBAQABBQL62Q8Y9LX2c9xrA0GAJaumtOj/xAAYEQADAQEAAAAAAAAAAAAAAAAAAhEBEv/aAAgBAwEBPwF25KM10p//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExAhIgYYH/2gAIAQEABj8C64bNko1XpgkiCxWyaP/EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExQVFhgZH/2gAIAQEAAT8hC3XSUurgxCG3QrBKZynUWtRy6IIgVk+VDAFBNa/yat6FTkHhn//aAAwDAQACAAMAAAAQd/g//8QAGREAAgMBAAAAAAAAAAAAAAAAABEBEDFR/9oACAEDAQE/EMXawMYp/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EA2yBDLL/8QAHRABAAMAAwEBAQAAAAAAAAAAAQARITFBYVGBof/aAAgBAQABPxAujtitJQEBeC+YppXe4KFeDZYUkvSoX+hcYgWySrPsGpZp8+HrCCGN4MEoYCgu4yplbXtGNx+kF/bfIWraqBf4z//Z')",
                    "backgroundSize": "cover",
                    "display": "block"
                  }
                }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e493701596b68f303394cd3ebba12f9b/8ac56/dracula-cover.webp 240w", "/static/e493701596b68f303394cd3ebba12f9b/d3be9/dracula-cover.webp 480w", "/static/e493701596b68f303394cd3ebba12f9b/87ca7/dracula-cover.webp 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/webp"
                  }}></source>{`
          `}<source parentName="picture" {...{
                    "srcSet": ["/static/e493701596b68f303394cd3ebba12f9b/09b79/dracula-cover.jpg 240w", "/static/e493701596b68f303394cd3ebba12f9b/7cc5e/dracula-cover.jpg 480w", "/static/e493701596b68f303394cd3ebba12f9b/1a144/dracula-cover.jpg 730w"],
                    "sizes": "(max-width: 730px) 100vw, 730px",
                    "type": "image/jpeg"
                  }}></source>{`
          `}<img parentName="picture" {...{
                    "className": "gatsby-resp-image-image",
                    "src": "/static/e493701596b68f303394cd3ebba12f9b/1a144/dracula-cover.jpg",
                    "alt": "Dracula Cover",
                    "title": "Dracula Cover",
                    "loading": "lazy",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    }
                  }}></img>{`
        `}</picture>{`
    `}</span></p>
            <div className="homeButtons">Dracula</div>
          </Link>
        </section>
      </details>
    </div>
    <hr />
    <br />
    <p>
  Ballet West boasts a rich and varied repertoire, elegant and versatile artists
  and an American style and legacy that is as dynamic, expansive and unexpected
  as the Rocky Mountain region it represents. Ballet West has toured the world
  several times over presenting the very best in American classical ballet.
    </p>
    <OutboundLink href="https://www.amgseniormedical.com/?utm_source=digital+playbill&utm_medium=ballet_west&utm_campaign=Cover" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAwQC/8QAFwEBAAMAAAAAAAAAAAAAAAAAAQACBP/aAAwDAQACEAMQAAABZ488g0Khc0U8OduKSW//xAAdEAADAQABBQAAAAAAAAAAAAAAAQIDFAQREhMh/9oACAEBAAEFAuRkhNaa0PDUxlxXxnLZXUeRPqa7jYqo/8QAGBEBAQADAAAAAAAAAAAAAAAAAAEREhP/2gAIAQMBAT8BjDpW9f/EABYRAQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPwHIj//EACEQAAEDAwQDAAAAAAAAAAAAAAABAhEDITEQEzIzcYGh/9oACAEBAAY/Almog9yOkTwcB24kGYOr6XpGdMFmT7P/xAAcEAEBAQEAAgMAAAAAAAAAAAABABEhMUFRkcH/2gAIAQEAAT8h00ZxSfPCScM0p+LZLRgWgYxausjjv303ePs3hIPNRyD/2gAMAwEAAgADAAAAELzKj//EABkRAAMAAwAAAAAAAAAAAAAAAAABESExQf/aAAgBAwEBPxBn0ovoUspH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBrCogg/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBcVGB0f/aAAgBAQABPxBsjF1Vk+seCKAuPOeMjHkcycJKgjtv3mkPdIWLloQICGISR9vzH+WAVofPMCVJY2HAaYQJJ2zGZFewZ//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9c39cea09f8cad0d319c21984acf42bd/8ac56/amg.webp 240w", "/static/9c39cea09f8cad0d319c21984acf42bd/d3be9/amg.webp 480w", "/static/9c39cea09f8cad0d319c21984acf42bd/87ca7/amg.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/9c39cea09f8cad0d319c21984acf42bd/09b79/amg.jpg 240w", "/static/9c39cea09f8cad0d319c21984acf42bd/7cc5e/amg.jpg 480w", "/static/9c39cea09f8cad0d319c21984acf42bd/1a144/amg.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/9c39cea09f8cad0d319c21984acf42bd/1a144/amg.jpg",
              "alt": "AMG Senior Medical Group",
              "title": "AMG Senior Medical Group",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>
    <OutboundLink href="https://www.barraesthetics.com/?utm_source=digital+playbill&utm_medium=ballet_west&utm_campaign=Cover" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB6IuQN4ll0g//xAAaEAACAwEBAAAAAAAAAAAAAAAAEQEQIUFC/9oACAEBAAEFAjUqekHT0or/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAYEAACAwAAAAAAAAAAAAAAAAABEAAgMf/aAAgBAQAGPwKZUsv/xAAbEAEBAAMAAwAAAAAAAAAAAAABEQAQITFBYf/aAAgBAQABPyGXJgYdAY7pTjyZZYdxRbJhfmhM93df/9oADAMBAAIAAwAAABAk0L7/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEQQf/aAAgBAwEBPxBjJWV//8QAFhEBAQEAAAAAAAAAAAAAAAAAEAFh/9oACAECAQE/ECaf/8QAHBABAQADAQADAAAAAAAAAAAAAREAITFBEIGR/9oACAEBAAE/ENkhvXMG0V8Ncy4XC0nca0EvmLWuSaqBUyQh/Wak14OEOnYX3VxZpr6+P//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/42bcbac5067aed04920dd0b8841f732d/8ac56/barr-aesthetics.webp 240w", "/static/42bcbac5067aed04920dd0b8841f732d/d3be9/barr-aesthetics.webp 480w", "/static/42bcbac5067aed04920dd0b8841f732d/87ca7/barr-aesthetics.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/42bcbac5067aed04920dd0b8841f732d/09b79/barr-aesthetics.jpg 240w", "/static/42bcbac5067aed04920dd0b8841f732d/7cc5e/barr-aesthetics.jpg 480w", "/static/42bcbac5067aed04920dd0b8841f732d/1a144/barr-aesthetics.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/42bcbac5067aed04920dd0b8841f732d/1a144/barr-aesthetics.jpg",
              "alt": "Barr Aesthetics",
              "title": "Barr Aesthetics",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>
    <OutboundLink href="https://www.americafirst.com/?utm_source=digital+playbill&utm_medium=ballet_west&utm_campaign=Cover" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "155.83333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEBQL/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQP/2gAMAwEAAhADEAAAAasbTTLmjQq5QbYNJRf/xAAdEAABBQADAQAAAAAAAAAAAAACAAEDERITISIk/9oACAEBAAEFAo6xwGiDLxv87SBU3oxl6tbFCVNad1//xAAXEQADAQAAAAAAAAAAAAAAAAAAARIQ/9oACAEDAQE/Actls//EABcRAAMBAAAAAAAAAAAAAAAAAAABEhD/2gAIAQIBAT8ByEQj/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAhAhMiIxYf/aAAgBAQAGPwKbQ2iPBcnBKyYpRqjU6V//xAAfEAEAAgEDBQAAAAAAAAAAAAABABEhEDFRQWGRobH/2gAIAQEAAT8ho26p+QYsCuY9SPEx+CPUKThZIsxSt4uADu6F6xmM8hN14ljP/9oADAMBAAIAAwAAABAEKM3/xAAWEQEBAQAAAAAAAAAAAAAAAAABEFH/2gAIAQMBAT8QY6x//8QAFhEBAQEAAAAAAAAAAAAAAAAAARBR/9oACAECAQE/ECGM/wD/xAAfEAEAAgMAAQUAAAAAAAAAAAABABEhUWFBMXGR0eH/2gAIAQEAAT8QoA2IPMoBaZelbmBpjYjYX2yU+KpX60fsM8IHydwd8RK4VEhanBYJoSaZhi9sWJt5PAYli1XtP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/44e45381e0c21d93de756f229f7060f7/8ac56/afcu.webp 240w", "/static/44e45381e0c21d93de756f229f7060f7/d3be9/afcu.webp 480w", "/static/44e45381e0c21d93de756f229f7060f7/87ca7/afcu.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/44e45381e0c21d93de756f229f7060f7/09b79/afcu.jpg 240w", "/static/44e45381e0c21d93de756f229f7060f7/7cc5e/afcu.jpg 480w", "/static/44e45381e0c21d93de756f229f7060f7/1a144/afcu.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/44e45381e0c21d93de756f229f7060f7/1a144/afcu.jpg",
              "alt": "America First Credit Union",
              "title": "America First Credit Union",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
    </OutboundLink>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      